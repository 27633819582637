<template>
    <div>
        <v-container fluid class="px-0">
            <v-card outlined elevation="3">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :items="dataTransaksiDonasi"
                        :items-per-page="itemsPerPage"
                        :loading="loading"
                        :page.sync="page"
                        hide-default-footer
                        dense
                    >
                        <!-- Top Tempalte -->
                        <template v-slot:top>
                            <v-toolbar flat dense class="mb-5">
                                <v-row>
                                    <v-col md="8">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="search"
                                            autocomple="off"
                                            placeholder="Search..."
                                            hide-details
                                            append-icon="mdi-magnify"
                                            :prepend-inner-icon="
                                                prependIconSearch
                                            "
                                            @click:append="searchData"
                                            @click:prepend-inner="clearSearch"
                                            v-on:keyup.enter="searchData"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col v-if="modify" md="4" align="right">
                                        <v-btn
                                            color="#61B15A"
                                            class="text-subtitle-2 white--text"
                                            style="text-transform: unset !important"
                                            @click="addData"
                                        >
                                            <v-icon left
                                                >mdi-plus-circle</v-icon
                                            >
                                            Tambah Surat Jalan
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <div
                                class="d-flex mb-2 px-4 justify-space-between align-center"
                            >
                                <v-row>
                                    <v-col cols="2">
                                        <v-select
                                            v-model="itemsPerPage"
                                            :items="showPerPage"
                                            outlined
                                            dense
                                            label="items per page"
                                            v-on:change="changeItemPerPage"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col align="right">
                                        <span class="mr-15 text-caption"
                                            >Page: {{ page }}</span
                                        >
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="prevPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-left
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="nextPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-right
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                        <!-- Date Tempalte -->
                        <template v-slot:item.tgl="{ item }">
                            {{ item.tgl | moment("DD/MM/YYYY") }}
                        </template>
                        <!-- Status Tempalte -->
                        <template v-slot:item.is_activated="{ item }">
                            <v-chip
                                small
                                class="font-weight-bold error--text"
                                v-if="item.is_activated === 0"
                            >
                                Disabled
                            </v-chip>
                            <v-chip
                                small
                                class="font-weight-bold primary--text"
                                v-else
                            >
                                Active
                            </v-chip>
                        </template>
                        <!-- Actions Tempalte -->
                        <template v-slot:item.actions="{ item, index }">
                            <template v-if="modify">
                                <v-btn
                                    v-if="item.isSJOUTNull !== null"
                                    small
                                    class="text-caption white--text mr-2 my-1"
                                    style="text-transform: unset !important"
                                    disabled
                                >
                                    Edit
                                </v-btn>
                                <v-btn
                                    v-else
                                    color="#FFA900"
                                    small
                                    class="text-caption white--text mr-2 my-1"
                                    style="text-transform: unset !important"
                                    @click.prevent="edit(index)"
                                >
                                    Edit
                                </v-btn>
                            </template>
                            <v-btn
                                color="#185ADB"
                                small
                                class="text-caption white--text mr-2"
                                style="text-transform: unset !important"
                                @click.prevent="cetak(index)"
                            >
                                Cetak Form
                            </v-btn>
                            <template v-if="modify">
                                <v-btn
                                    v-if="item.isSJOUTNull !== null"
                                    color="#EB4646"
                                    small
                                    class="text-caption white--text mr-2 my-1"
                                    style="text-transform: unset !important"
                                    disabled
                                >
                                    <span v-if="item.is_activated === 0"
                                        >Active</span
                                    >
                                    <span v-else>Disabled</span>
                                </v-btn>
                                <v-btn
                                    v-else
                                    color="#EB4646"
                                    small
                                    class="text-caption white--text mr-2 my-1"
                                    style="text-transform: unset !important"
                                    @click.prevent="hapus(index)"
                                >
                                    <span v-if="item.is_activated === 0"
                                        >Active</span
                                    >
                                    <span v-else>Disabled</span>
                                </v-btn>
                            </template>
                        </template>
                    </v-data-table>
                    <!-- Bottom Pagination -->
                    <div
                        class="d-flex mt-5 px-4 justify-space-between align-center"
                    >
                        <v-row>
                            <v-col cols="2">
                                <v-select
                                    v-model="itemsPerPage"
                                    :items="showPerPage"
                                    outlined
                                    dense
                                    label="items per page"
                                    v-on:change="changeItemPerPage"
                                >
                                </v-select>
                            </v-col>
                            <v-col align="right">
                                <span class="mr-15 text-caption"
                                    >Page: {{ page }}</span
                                >
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="prevPage"
                                >
                                    <v-icon dark> mdi-chevron-left </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="nextPage"
                                >
                                    <v-icon dark> mdi-chevron-right </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>

        <add-data
            :addDataDialog="addDataDialog"
            :dataCustomer="dataCustomer"
            :listGudang="listGudang"
            :moving_state_id="moving_state_id"
            @closeAddDataDialog="closeAddDataDialog"
            @reloadDataAdd="reloadDataAdd"
        />

        <olah-data
            :olahDataDialog="olahDataDialog"
            :olahData="olahData"
            :dataCustomer="dataCustomer"
            :listGudang="listGudang"
            :moving_state_id="moving_state_id"
            :listNama="listNama"
            @closeOlahDataDialog="closeOlahDataDialog"
            @reloadOlahData="reloadOlahData"
        />

        <cetak-form
            :cetakFormDialog="cetakFormDialog"
            :olahData="olahData"
            :summList="summList"
            @closeCetakFormDialog="closeCetakFormDialog"
        />

        <v-dialog v-model="progress" width="500">
            <v-card>
                <v-card-text class="pt-5">
                    <v-progress-linear
                        indeterminate
                        color="#FFA900"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="white" rounded>
                <v-card-title class="justify-center text-h6 black--text">
                    {{ popTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h6 font-weight-bold black--text">
                    Ubah status Surat Jalan
                </v-card-title>
                <v-card-subtitle class="mt-1 text-subtitle-2 black--text">
                    Status Surat Jalan akan dirubah. Yakin melanjutkan proses
                    ini?
                </v-card-subtitle>
                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="closeDelete"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="#EB4646"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="deleteItemConfirm"
                        :loading="loadingDelete"
                    >
                        Proses
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CetakForm from "./cetakForm.vue";
import olahData from "./olahData.vue";
import AddData from "./addData.vue";

export default {
    components: { olahData, CetakForm, AddData },
    props: { modify: Boolean },
    data: () => ({
        search: "",
        loading: false,
        prependIconSearch: "",
        headers: [
            {
                text: "Created Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "tgl",
                width: "150px",
            },
            {
                text: "Surat Jalan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "nomer_seri",
                width: "150px",
            },
            {
                text: "Status",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "is_activated",
                width: "150px",
            },
            {
                text: "Action",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "actions",
                width: "300px",
            },
        ],
        moving_state_id: "",
        dataTransaksiDonasi: [],
        dataCustomer: [],
        tipeGudang: "",
        listGudang: [],
        listNama: [],
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
        addDataDialog: false,
        olahDataDialog: false,
        cetakFormDialog: false,
        progress: false,
        popDialog: false,
        popTitle: "",
        olahData: {},
        summList: [],
        dialogDelete: false,
        loadingDelete: false,
        deleteData: {},
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        getData() {
            this.loading = true;
            axios
                .get(
                    "v1/admin/surat_jalan/donasi?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page
                )
                .then((response) => {
                    this.loading = false;
                    this.dataTransaksiDonasi = response.data.data;
                    this.getGudang();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getData();
                        }
                    }
                });
        },
        getDataFilter(value) {
            this.loading = true;
            axios
                .get(
                    "v1/admin/surat_jalan/donasi?nomer_seri=" +
                        value +
                        "&limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page
                )
                .then((response) => {
                    this.loading = false;
                    this.dataTransaksiDonasi = response.data.data;
                    this.getGudang();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getData();
                        }
                    }
                });
        },
        getTransType() {
            axios
                .get("v1/user/Scan/Out")
                .then((response) => {
                    response.data.data.forEach((element) => {
                        if (element.inisial === "DONASI") {
                            this.moving_state_id = element.id;
                        }
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getData();
                        }
                    }
                });
        },
        getGudang() {
            this.listGudang = [];
            this.user.listGudang.forEach((v) => {
                axios.get("v1/user/gudang?id=" + v).then((response) => {
                    if (response.data.data.is_activated === 1) {
                        this.listGudang.push(response.data.data);
                    }
                });
            });

            this.dataCustomer = [];
            axios.get("v1/admin/customer/list?limit=9999").then((response) => {
                response.data.data.forEach((v) => {
                    if (v.is_activated === 1) {
                        this.dataCustomer.push(v);
                    }
                });
            });
        },
        changeItemPerPage() {
            if (this.search === null) {
                this.getData();
            } else {
                this.getDataFilter(this.search);
            }
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                if (this.search === null) {
                    this.getData();
                } else {
                    this.getDataFilter(this.search);
                }
            }
        },
        nextPage() {
            if (this.dataBarcode === null) {
                return;
            } else {
                this.page = this.page + 1;
                if (this.search === null) {
                    this.getData();
                } else {
                    this.getDataFilter(this.search);
                }
            }
        },
        addData() {
            this.getTransType();
            this.addDataDialog = true;
        },
        searchData() {
            if (this.search === null) {
                this.clearSearch();
            } else {
                this.prependIconSearch = "mdi-close";
                this.dataTransaksiDonasi = [];
                this.getDataFilter(this.search);
            }
        },
        clearSearch() {
            this.search = null;
            this.prependIconSearch = "";
            this.dataTransaksiDonasi = [];
            this.getData();
        },
        async edit(index) {
            this.getTransType();
            this.olahData = this.dataTransaksiDonasi[index];
            var tgl = this.dataTransaksiDonasi[index].tgl
                .split(" ")[0]
                .split("-");
            var date = tgl[2].split("T");
            this.olahData.tgl = tgl[0] + "-" + tgl[1] + "-" + date[0];
            axios
                .get(
                    "v1/admin/signature/list?gudang_id=" +
                        this.dataTransaksiDonasi[index].gudang_asal_id
                )
                .then((response) => {
                    response.data.data.forEach((v) => {
                        if (v.is_activated === 1) {
                            this.listNama.push(v);
                        }
                    });
                });
            await axios
                .get(
                    "v1/admin/laporan/sj_summ/list?surat_jalan_id=" +
                        this.dataTransaksiDonasi[index].id
                )
                .then((response) => {
                    this.olahData.selectMaterial = response.data.data;
                    this.olahDataDialog = true;
                });
        },
        hapus(id) {
            this.deleteData = this.dataTransaksiDonasi[id];
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.loadingDelete = true;
            const data = this.deleteData;
            if (this.deleteData.is_activated === 0) {
                data.is_activated = 1;
            } else {
                data.is_activated = 0;
            }
            const dataSend = JSON.stringify(data);
            axios
                .put("v1/admin/surat_jalan/donasi", dataSend, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 201) {
                        this.loadingDelete = false;
                        this.closeDelete();
                    }
                });
        },
        closeDelete() {
            this.dataTransaksiDonasi = [];
            this.deleteData = {};
            this.getData();
            this.dialogDelete = false;
        },
        cetak(index) {
            this.olahData = this.dataTransaksiDonasi[index];
            axios
                .get(
                    "v1/admin/laporan/sj_summ/list?surat_jalan_id=" +
                        this.dataTransaksiDonasi[index].id
                )
                .then((response) => {
                    this.summList = response.data.data;
                    this.cetakFormDialog = true;
                });
        },
        reloadDataAdd() {
            this.popTitle = "Transaksi Donasi berhasil ditambahkan";
            this.popDialog = true;
        },
        reloadOlahData() {
            this.popTitle = "Transaksi Donasi berhasil diperbaharui";
            this.popDialog = true;
        },
        closeAddDataDialog() {
            this.dataTransaksiDonasi = [];
            this.getData();
            this.addDataDialog = false;
        },
        closeOlahDataDialog() {
            this.dataTransaksiDonasi = [];
            this.getData();
            this.olahDataDialog = false;
        },
        closeCetakFormDialog() {
            this.dataTransaksiDonasi = [];
            this.getData();
            this.olahData = {};
            this.cetakFormDialog = false;
        },
        closePopDialog() {
            this.popTitle = "";
            this.popDialog = false;
        },
    },

    created() {
        this.getData();
    },
};
</script>
