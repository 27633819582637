<template>
    <div>
        <v-dialog v-model="addDataDialog" persistent max-width="80%">
            <v-card>
                <v-card-title class="text-h5 font-weight-bold">
                    Tambah Surat Jalan Donasi
                </v-card-title>
                <v-divider class="mx-5"></v-divider>

                <v-card-text class="mt-3">
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col>
                                <!-- Surat Jalan -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Nomor Surat Jalan
                                </v-card-title>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="olahData.nomer_seri"
                                    :rules="[rules.required]"
                                    hide-details
                                    readonly
                                    filled
                                ></v-text-field>
                                <!-- Tanggal -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Tanggal
                                </v-card-title>
                                <v-menu
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-left="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="olahData.tgl"
                                            append-icon="mdi-calendar-month"
                                            placeholder="Pilih tanggal"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="olahData.tgl"
                                        @input="dateMenu = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <!-- Gudang Asal -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Gudang Asal
                                </v-card-title>
                                <v-select
                                    placeholder="Gudang Asal"
                                    dense
                                    outlined
                                    v-model="olahData.gudang_asal_id"
                                    :items="listGudang"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    v-on:change="changeGudangAsal"
                                ></v-select>
                                <!-- Penerima -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Penerima
                                </v-card-title>
                                <v-autocomplete
                                    placeholder="Penerima"
                                    dense
                                    outlined
                                    v-model="olahData.customer_id"
                                    :items="dataCustomer"
                                    item-text="nama"
                                    item-value="id"
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Note (Opsional)
                                </v-card-title>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="olahData.notes"
                                    hide-details
                                    :maxlength="maxnote"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <!-- No. Kendaraan -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Nomor Kendaraan (Opsional)
                                </v-card-title>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="olahData.no_kendaraan"
                                    hide-details
                                ></v-text-field>
                                <!-- Dibuat Oleh -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Dibuat Oleh
                                </v-card-title>
                                <v-select
                                    v-model="olahData.dibuat_id"
                                    :items="listNama"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    dense
                                    outlined
                                ></v-select>
                                <!-- Disetujui Oleh -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Disetujui Oleh
                                </v-card-title>
                                <v-select
                                    v-model="olahData.disetujui_id"
                                    :items="listNama"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    dense
                                    outlined
                                ></v-select>
                                <!-- Dikeluarkan Oleh -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Dikeluarkan Oleh
                                </v-card-title>
                                <v-select
                                    v-model="olahData.dikeluarkan_id"
                                    :items="listNama"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-divider class="mb-5"></v-divider>
                        <!-- Material -->
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    placeholder="Search"
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table
                                    v-model="selectMaterial"
                                    item-key="id"
                                    show-select
                                    :search="search"
                                    :headers="headersMaterial"
                                    :items="indexedItems"
                                    :items-per-page="itemsPerPage"
                                    :loading="loadingTable"
                                    :page.sync="page"
                                    hide-default-footer
                                    dense
                                >
                                    <template v-slot:top>
                                        <v-row>
                                            <v-col cols="2">
                                                <v-select
                                                    v-model="itemsPerPage"
                                                    :items="showPerPage"
                                                    outlined
                                                    dense
                                                    label="items per page"
                                                    v-on:change="
                                                        changeItemPerPage
                                                    "
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col align="right">
                                                <span class="mr-15 text-caption"
                                                    >Page: {{ page }}</span
                                                >
                                                <v-btn
                                                    fab
                                                    x-small
                                                    depressed
                                                    color="primary"
                                                    class="mx-3"
                                                    @click="prevPage"
                                                >
                                                    <v-icon dark>
                                                        mdi-chevron-left
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    fab
                                                    x-small
                                                    depressed
                                                    color="primary"
                                                    class="mx-3"
                                                    @click="nextPage"
                                                >
                                                    <v-icon dark>
                                                        mdi-chevron-right
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:item.qty="{ item }">
                                        <span>
                                            {{ getQtyStock(item) }}
                                        </span>
                                    </template>

                                    <template v-slot:item.qty_kirim="{ item }">
                                        <v-text-field
                                            type="number"
                                            outlined
                                            dense
                                            :rules="[
                                                rules.maxQty(
                                                    item.qty_kirim,
                                                    item.total_stock,
                                                    () =>
                                                        getItemStockDivider(
                                                            item.satuan,
                                                            item.mat_code,
                                                            item.is_meta
                                                        )
                                                ),
                                            ]"
                                            v-model="item.qty_kirim"
                                        ></v-text-field>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <!-- Actions Button -->
                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="closeAddData"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="#61B15A"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="checkType"
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Simpan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Notification Dialog -->
        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="white" rounded>
                <v-card-title class="justify-center text-h6 black--text">
                    {{ popTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
    props: {
        addDataDialog: Boolean,
        dataCustomer: Array,
        listGudang: Array,
        moving_state_id: String,
    },

    data: () => ({
        maxnote: 40,
        valid: false,
        loading: false,
        loadingTable: false,
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
        dateMenu: false,
        search: "",
        rules: {
            required: (value) => !!value || "Tidak boleh kosong !!",
            maxQty(value, stock, callbackDivider) {
                var max = stock;
                const divider = callbackDivider();
                max = stock / divider;
                return Number(value || "") <= Number(max) || `Maximal ${max}`;
            },
        },
        olahData: {
            no_do_sales: "",
            no_kendaraan: "",
            gudang_penerima_id: "00000000-0000-0000-0000-000000000000",
            dibuat_id: "",
            disetujui_id: "",
            dikeluarkan_id: "",
            diterima_id: "00000000-0000-0000-0000-000000000000",
            notes: "",
        },
        selectMaterial: [],
        list_summary: [],
        listNama: [],
        headersMaterial: [
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material_code",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "Satuan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "satuan",
            },
            {
                text: "Quantity Stock",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qty",
            },
            {
                text: "Quantity Kirim",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qty_kirim",
            },
        ],
        dataMaterial: [],
        check: 0,
        popDialog: false,
        popTitle: "",
    }),

    computed: {
        indexedItems() {
            return this.dataMaterial.map((item, index) => ({
                id: index,
                ...item,
            }));
        },
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        async changeGudangAsal() {
            this.getNo();
            await axios
                .get(
                    "v1/admin/signature/list?gudang_id=" +
                        this.olahData.gudang_asal_id
                )
                .then((response) => {
                    this.olahData.dibuat_id = null;
                    this.olahData.disetujui_id = null;
                    this.olahData.dikeluarkan_id = null;
                    response.data.data.forEach((v) => {
                        if (v.is_activated === 1) {
                            this.listNama.push(v);
                        }
                    });
                    this.populateDataNama(response.data.data);
                    this.getMaterial();
                });
        },
        async populateDataNama(value) {
            value.forEach((element) => {
                if (element.is_activated === 1) {
                    axios
                        .get(
                            "v1/admin/signature_type?id=" +
                                element.signature_type_id
                        )
                        .then((response) => {
                            if (response.data.data.kode === "DIBUAT_OLEH") {
                                this.olahData.dibuat_id = element.id;
                            }
                            if (response.data.data.kode === "DISETUJUI_OLEH") {
                                this.olahData.disetujui_id = element.id;
                            }
                            if (
                                response.data.data.kode === "DIKELUARKAN_OLEH"
                            ) {
                                this.olahData.dikeluarkan_id = element.id;
                            }
                        });
                }
            });
        },
        getMaterial() {
            this.dataMaterial = [];
            this.loadingTable = true;
            axios
                .get(
                    "v1/admin/laporan/gudang/list?gudang_id=" +
                        this.olahData.gudang_asal_id +
                        "&limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page
                )
                .then((response) => {
                    this.dataMaterial = response.data.data;
                    this.loadingTable = false;
                });
        },
        async getNo() {
            await axios
                .get(
                    "v1/admin/surat/seri?type=SJD&gudang_id=" +
                        this.olahData.gudang_asal_id
                )
                .then((response) => {
                    this.olahData.nomer_seri = response.data.data;
                });
        },
        changeItemPerPage() {
            if (this.dataMaterial.length === 0) {
                return;
            } else {
                this.getMaterial();
            }
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.getMaterial();
            }
        },
        nextPage() {
            if (this.dataMaterial.length === 0) {
                return;
            } else {
                this.page = this.page + 1;
                this.getMaterial();
            }
        },
        checkType() {
            if (this.selectMaterial.length === 0) {
                this.popTitle = "Pilih Material !!";
                this.popDialog = true;
            } else {
                this.selectMaterial.forEach((element) => {
                    if (element.qty_kirim === "") {
                        this.check = 1;
                    }
                });
                if (this.check === 0) {
                    this.save();
                } else {
                    this.popTitle =
                        "Jumlah Quantity Kirim Tidak Boleh Kosong !!!";
                    this.check = 0;
                    this.popDialog = true;
                }
            }
        },
        save() {
            this.loading = true;
            this.olahData.no_kendaraan = this.olahData.no_kendaraan.toUpperCase();
            this.olahData.moving_state_id = this.moving_state_id;

            var list = {};
            this.selectMaterial.forEach((v) => {
                list.batch_no = v.batch_no;
                list.type_box_id = v.type_box_id;
                list.total = v.qty_kirim;
                list.total_diterima = "0";
                this.list_summary.push(list);
                list = {};
            });
            const data = JSON.stringify(this.olahData);
            axios
                .post("v1/admin/surat_jalan/donasi", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((r) => {
                    const summ = {};
                    summ.id = r.data.data.genUID;
                    summ.list_summary = this.list_summary;
                    const summSend = JSON.stringify(summ);
                    axios
                        .put("v1/admin/surat_jalan/summ", summSend, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        })
                        .then(() => {
                            this.loading = false;
                            this.$emit("reloadDataAdd");
                            this.closeAddData();
                        });
                });
        },
        closeAddData() {
            this.loading = false;
            this.resetData();
            this.$refs.form.resetValidation();
            this.$emit("closeAddDataDialog");
        },
        resetData() {
            this.selectMaterial = [];
            this.dataMaterial = [];
            this.check = 0;
            this.olahData.nomer_seri = "";
            this.olahData.gudang_asal_id = "";
            this.olahData.gudang_penerima_id =
                "00000000-0000-0000-0000-000000000000";
            this.olahData.no_do_sales = "";
            this.olahData.tgl = null;
            this.olahData.no_kendaraan = "";
            this.olahData.customer_id = "";
            this.olahData.dibuat_id = "";
            this.olahData.disetujui_id = "";
            this.olahData.dikeluarkan_id = "";
            this.olahData.diterima_id = "00000000-0000-0000-0000-000000000000";
            this.olahData.notes = "";
            this.list_summary = [];
        },
        closePopDialog() {
            this.popTitle = "";
            this.popDialog = false;
        },
    },
};
</script>
