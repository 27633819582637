<template>
    <div>
        <v-dialog v-model="cetakFormDialog" persistent max-width="80%">
            <v-card class="pa-5">
                <div id="printMe">
                    <div style="border: 1px solid black">
                        <!-- Header -->
                        <div style="margin-left: 15px; margin-top: 5px">
                            <b style="font-size: 11pt"
                                >PT. Rhea Sciences Indonesia</b
                            >
                        </div>
                        <!-- Judul -->
                        <div style="margin-bottom: 18.75pt">
                            <table style="border: none" width="100%">
                                <tr>
                                    <td
                                        class="text-dark"
                                        align="center"
                                        style="padding: 2px; font-size: 16pt"
                                    >
                                        <b>SURAT JALAN DONASI</b>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <!-- Info -->
                        <div style="margin-left: 15px; margin-right: 15px">
                            <table
                                style="
                  font-size: 11pt;
                  width: 40%;
                  float: left;
                  margin-bottom: 30pt;
                "
                            >
                                <tr>
                                    <td>Nomor : {{ olahData.nomer_seri }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Tanggal : {{ formatDate(olahData.tgl) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        No. Kendaraan :
                                        {{ olahData.no_kendaraan }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Note : {{ olahData.notes }}</td>
                                </tr>
                            </table>

                            <table
                                style="
                  font-size: 11pt;
                  width: 50%;
                  float: right;
                  margin-bottom: 30pt;
                "
                            >
                                <tr>
                                    <td>
                                        Asal Gudang :
                                        {{ olahData.gudang_asal_name }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Penerima : {{ olahData.customer_name }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <!-- Material -->
                        <div
                            style="margin-top: 20px; margin-left: 15px; margin-right: 15px"
                        >
                            <table
                                style="
                  font-size: 11pt;
                  border: 1px solid black;
                  border-collapse: collapse;
                "
                                width="100%"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            rowspan="2"
                                            style="padding: 2px; border: 1px solid black"
                                        >
                                            No
                                        </th>
                                        <th
                                            rowspan="2"
                                            style="padding: 2px; border: 1px solid black"
                                        >
                                            Material
                                        </th>
                                        <th
                                            rowspan="2"
                                            style="padding: 2px; border: 1px solid black"
                                        >
                                            Batch
                                        </th>
                                        <th
                                            rowspan="2"
                                            style="padding: 2px; border: 1px solid black"
                                        >
                                            Satuan
                                        </th>
                                        <th
                                            colspan="2"
                                            style="padding: 2px; border: 1px solid black"
                                        >
                                            Qty
                                        </th>
                                    </tr>
                                    <tr>
                                        <th
                                            style="padding: 2px; border: 1px solid black"
                                        >
                                            Kirim
                                        </th>
                                        <th
                                            style="padding: 2px; border: 1px solid black"
                                        >
                                            Terima
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        style="text-align: center"
                                        v-for="(list, index) in summList"
                                        :key="list.id"
                                    >
                                        <td style="padding: 5px">
                                            {{ index + 1 }}
                                        </td>
                                        <td style="padding: 5px">
                                            {{ list.material_code }}
                                        </td>
                                        <td style="padding: 5px">
                                            {{ list.batch_no }}
                                        </td>
                                        <td style="padding: 5px">
                                            {{ list.satuan }}
                                        </td>
                                        <td style="padding: 5px">
                                            {{ list.total }}
                                        </td>
                                        <td style="padding: 5px"></td>
                                    </tr>
                                    <tr
                                        v-if="totalMc !== 0"
                                        style="text-align: center"
                                    >
                                        <td
                                            colspan="4"
                                            style="padding: 5px; border: 1px solid black"
                                        >
                                            Total MC
                                        </td>
                                        <td
                                            style="padding: 5px; border: 1px solid black"
                                        >
                                            {{ totalMc }}
                                        </td>
                                        <td
                                            style="padding: 5px; border: 1px solid black"
                                        ></td>
                                    </tr>
                                    <tr
                                        v-if="totalIc !== 0"
                                        style="text-align: center"
                                    >
                                        <td
                                            colspan="4"
                                            style="padding: 5px; border: 1px solid black"
                                        >
                                            Total IC
                                        </td>
                                        <td
                                            style="padding: 5px; border: 1px solid black"
                                        >
                                            {{ totalIc }}
                                        </td>
                                        <td
                                            style="padding: 5px; border: 1px solid black"
                                        ></td>
                                    </tr>
                                    <tr
                                        v-if="totalBtl !== 0"
                                        style="text-align: center"
                                    >
                                        <td
                                            colspan="4"
                                            style="padding: 5px; border: 1px solid black"
                                        >
                                            Total Botol
                                        </td>
                                        <td
                                            style="padding: 5px; border: 1px solid black"
                                        >
                                            {{ totalBtl }}
                                        </td>
                                        <td
                                            style="padding: 5px; border: 1px solid black"
                                        ></td>
                                    </tr>
                                    <tr
                                        style="text-align: center"
                                        v-if="!isMeta(summList)"
                                    >
                                        <td
                                            colspan="4"
                                            style="padding: 5px; border: 1px solid black"
                                        >
                                            {{ textGrandTotal(summList) }}
                                        </td>
                                        <td
                                            style="padding: 5px; border: 1px solid black"
                                        >
                                            {{ totalItem }}
                                        </td>
                                        <td
                                            style="padding: 5px; border: 1px solid black"
                                        ></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- Footer -->
                        <div
                            style="
                margin-top: 30pt;
                margin-bottom: 14px;
                margin-left: 15px;
                margin-right: 15px;
              "
                        >
                            <table style="font-size: 11pt" width="100%">
                                <td align="left" width="25%">
                                    Dibuat oleh,
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {{ olahData.dibuat_name }}
                                    <br />
                                    ({{ olahData.dibuat_jabatan }})
                                </td>
                                <td align="left" width="25%">
                                    Disetujui oleh,
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {{ olahData.disetujui_name }}
                                    <br />
                                    ({{ olahData.disetujui_jabatan }})
                                </td>
                                <td align="left" width="25%">
                                    Dikeluarkan oleh,
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {{ olahData.dikeluarkan_name }}
                                    <br />
                                    ({{ olahData.dikeluarkan_jabatan }})
                                </td>
                                <td align="right" width="25%">
                                    Diterima oleh,
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    ................................
                                    <br />
                                    (<font v-for="i in 30" :key="i">&nbsp;</font
                                    >)
                                </td>
                            </table>
                        </div>
                    </div>
                </div>

                <v-card-actions class="px-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        class="text-subtitle-2"
                        style="text-transform: unset !important"
                        @click="closeCetakForm"
                    >
                        Tutup
                    </v-btn>
                    <v-btn
                        color="#61B15A"
                        class="text-subtitle-2 white--text"
                        style="text-transform: unset !important"
                        @click="print"
                    >
                        <v-icon left>mdi-printer</v-icon>
                        Cetak
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        cetakFormDialog: Boolean,
        olahData: Object,
        summList: Array,
    },

    computed: {
        totalItem: function() {
            let sum = 0;
            this.summList.forEach((summList) => {
                const { satuan, is_meta, mat_code } = summList;
                let multiplier = this.getTotalStockMultiplier(
                    satuan,
                    mat_code,
                    is_meta
                );
                sum += summList.total * multiplier;
            });
            return sum;
        },
        totalMc: function() {
            let sumMc = 0;
            this.summList.forEach((m) => {
                if (m.satuan === "MC") {
                    sumMc += m.total;
                }
            });
            return sumMc;
        },
        totalIc: function() {
            let sumIc = 0;
            this.summList.forEach((m) => {
                if (m.satuan === "IC") {
                    sumIc += m.total;
                }
            });
            return sumIc;
        },
        totalBtl: function() {
            let sumBtl = 0;
            this.summList.forEach((m) => {
                if (m.satuan !== "MC" && m.satuan !== "IC") {
                    sumBtl += m.total;
                }
            });
            return sumBtl;
        },
    },

    methods: {
        async print() {
            await this.$htmlToPaper("printMe");
        },
        formatDate(value) {
            var monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            var newDate = new Date(value);
            return (
                newDate.getDate() +
                " " +
                monthNames[newDate.getMonth()] +
                " " +
                newDate.getFullYear()
            );
        },
        closeCetakForm() {
            this.$emit("closeCetakFormDialog");
        },
    },
};
</script>
